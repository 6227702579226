<template>
  <div>
    <el-drawer
      title="上传详情"
      :visible="uploadVideoRecordDetailShow"
      size="1200px"
      @close="close"
      @opened="openInit"
    >
      <div>
        <ad-table-page
          :showForm="true"
          v-model="formData"
          :tableConfig="tableConfig"
          :filedList="filedList"
          class="h-full"
          :formPrams="{
            inline: true,
            size: 'medium',
          }"
        >
          <template #video="{ row }">
            <div>
              <el-image
                class="w-[100px] h-[100px]"
                :src="row.thumbnail"
                fit="contain"
                v-if="row.thumbnail"
              ></el-image>
              <span v-else>--</span>
            </div>
          </template>
        </ad-table-page>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { adTablePage } from '@adCommonComponent';
import { videoRecordDetail, getChannelList } from '@/api/google/youtube.js';
import { uploadStatus } from '@/views/google/youtube/index.js';
export default {
  props: {
    uploadVideoRecordDetailShow: {
      type: Boolean,
      default: false,
    },
    youtubeChannelId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    adTablePage,
  },
  data() {
    return {
      formData: {},
      selectRow: [],
      params: {},
      uploadStatus,
      filedList: {
        youtubeChannelId: {
          label: '',
          key: 'youtubeChannelId',
          type: 'select',
          placeholder: 'YouTube频道',
          options: [],
        },
        status: {
          label: '',
          key: 'status',
          type: 'select',
          placeholder: '状态',
          options: uploadStatus,
        },
        date: {
          label: '',
          key: 'date',
          type: 'dateRange',
          change: 'changeLike',
          startKey: 'startDate',
          endKey: 'endDate',
          config: {
            'value-format': 'yyyy-MM-dd',
          },
        },
        btn: {
          type: 'btn',
        },
      },

      tableConfig: {
        api: videoRecordDetail,
        rowKey: 'recordId',
        columns: [
          {
            label: '视频',
            prop: 'video',
          },

          {
            label: '视频标题',
            prop: 'videoTitle',
          },
          {
            label: '上传人',
            prop: 'updateUserName',
          },
          {
            label: '上传结果',
            prop: 'uploadResult',
          },
        ],
        showPage: true,
        refresh: true,
        formatterParams: this.formatterParams,
        formatterTableData(res) {
          return {
            list: res.data.content,
            total: res.data.totalElements,
          };
        },
      },
    };
  },
  methods: {
    /**
     * 初始化请求参数
     */
    formatterParams(params) {
      let { order, pageNum, prop, ...data } = params;
      return {
        page: pageNum,
        ...data,
        youtubeChannelId: this.youtubeChannelId,
      };
    },
    /**
     * 打开弹窗初始化
     */
    openInit() {
      //   this.videoList = [];
      this.tableConfig.refresh = !this.tableConfig.refresh;
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.$emit('update:uploadVideoRecordDetailShow', false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  position: relative;
  padding: 20px;
}
::v-deep .videoItem {
  height: 240px;
  .el-checkbox__input {
    position: absolute;
  }
  .el-checkbox__label {
    height: 240px;
  }
}
</style>
