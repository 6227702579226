<template>
  <div>
    <el-drawer
      title="上传记录"
      :visible="uploadVideoRecordShow"
      size="1200px"
      @close="close"
      @opened="openInit"
    >
      <div>
        <ad-table-page
          :showForm="true"
          v-model="formData"
          :tableConfig="tableConfig"
          :filedList="filedList"
          class="h-full"
          :formPrams="{
            inline: true,
            size: 'medium',
          }"
          @reset="formData = {}"
        >
          <!-- <template #result="{ row }">
            <div>
              <span class="text-green-500">执行成功：{{ row.youtubeVideoDescCount.successCount }}条； </span>
              <span class="text-red-500">执行失败：{{ row.youtubeVideoDescCount.failCount }}条； </span>
              <span>执行中：{{ row.youtubeVideoDescCount.processCount }} 条</span>
            </div>
          </template> -->
          <template #video="{ row }">
            <div>
              <!-- <el-image
                class="w-[80px] h-[80px]"
                :src="row.thumbnail"
                fit="contain"
                v-if="row.thumbnail"
              ></el-image> -->
              <video
                class="w-[80px] h-[80px]"
                :src="row.ossFileUrl"
                v-if="row.ossFileUrl"
              ></video>
              <span v-else>--</span>
            </div>
          </template>
        </ad-table-page>
      </div>
    </el-drawer>

    <UploadVideoRecordDetail
      :uploadVideoRecordDetailShow.sync="uploadVideoRecordDetailShow"
      :youtubeChannelId="youtubeChannelId"
    />
  </div>
</template>
<script>
import { adTablePage } from '@adCommonComponent';
import { videoRecord, getChannelList, videoRecordDetail } from '@/api/google/youtube.js';
import { uploadStatus } from '@/views/google/youtube/index.js';
import UploadVideoRecordDetail from '@/views/google/components/UploadVideoRecordDetail/index.vue';
import { filteredObj, fnCSTToYmd } from '@/utils/utils.js';
export default {
  props: {
    uploadVideoRecordShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    adTablePage,
    UploadVideoRecordDetail,
  },
  data() {
    return {
      formData: {},
      selectRow: [],
      params: {},
      uploadStatus,
      filedList: {
        youtubeChannelId: {
          label: '',
          key: 'youtubeChannelId',
          type: 'select',
          placeholder: 'YouTube频道',
          options: [],
        },
        status: {
          label: '',
          key: 'status',
          type: 'select',
          placeholder: '状态',
          options: uploadStatus,
        },
        date: {
          label: '',
          key: 'date',
          type: 'dateRange',
          change: 'changeLike',
          startKey: 'startDate',
          endKey: 'endDate',
          config: {
            'value-format': 'yyyy-MM-dd HH:mm:ss',
            'picker-options': {
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
            },
          },
        },
        btn: {
          type: 'btn',
        },
      },

      tableConfig: {
        api: videoRecordDetail,
        rowKey: 'recordId',
        columns: [
          {
            label: '视频',
            prop: 'video',
          },

          {
            label: '视频标题',
            prop: 'videoTitle',
          },
          {
            label: '上传人',
            prop: 'updateUserName',
          },
          {
            prop: 'startDate',
            label: '上传时间',
          },
          {
            label: '上传结果',
            prop: 'uploadResult',
          },
        ],
        showPage: true,
        refresh: true,
        showSelectBox: true,
        selectable: true,
        formatterParams(params) {
          console.log(params);
          let { order, pageNum, prop, ...data } = { ...params, ...this.formData };
          //   data.dateHour = encodeURIComponent(data.dateHour);
          return {
            page: pageNum,
            pageSize: params.pageSize,
            ...filteredObj(data),
          };
        },
        formatterTableData(res) {
          return {
            list: res.data.list,
            total: res.data.total,
          };
        },
      },
      uploadVideoRecordDetailShow: false, //记录详情
      youtubeChannelId: null, //当前选择的频道
    };
  },
  methods: {
    /**
     * 获取频道列表
     */
    getChannel() {
      getChannelList().then((res) => {
        this.filedList['youtubeChannelId'].options = res.data.map((v) => {
          return {
            id: v.id,
            name: v.channelName,
          };
        });
      });
    },
    /**
     * 打开弹窗初始化
     */
    openInit() {
      this.getChannel();
      console.log(new Date().setHours(0, 0, 0, 0));
      let startDate = fnCSTToYmd(new Date(new Date().getTime()), { defhms: '00:00:00' });
      let endDate = fnCSTToYmd(new Date(new Date().getTime()));
      this.$set(this.formData, 'startDate', startDate);
      this.$set(this.formData, 'endDate', endDate);
      this.$set(this.formData, 'date', [startDate, endDate]);
      //   this.videoList = [];
      this.$nextTick(() => {
        this.tableConfig.refresh = !this.tableConfig.refresh;
      });
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.$emit('update:uploadVideoRecordShow', false);
    },
    /**
     * 查看详情
     */
    lookDetail(row) {
      console.log(row);
      this.youtubeChannelId = row.youtubeChannelId;
      this.uploadVideoRecordDetailShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  position: relative;
  padding: 20px;
}
::v-deep .videoItem {
  height: 240px;
  .el-checkbox__input {
    position: absolute;
  }
  .el-checkbox__label {
    height: 240px;
  }
}
</style>
