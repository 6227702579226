<template>
  <div>
    <el-drawer
      title="添加用户"
      :visible="addUserShow"
      size="800px"
      @close="close"
      @opened="openInit"
    >
      <div>
        <el-form
          ref="form"
          :model="formData"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item label="请选择用户">
            <el-select
              v-model="formData.user"
              placeholder="请选择用户"
              value-key="id"
              class="w-full"
              multiple
              filterable
              remote
              reserve-keyword
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="`${item.name}(${item.id})`"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="absolute right-3 bottom-5">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { queryGkUser, batchAddChannelUser } from '@/api/google/youtube.js';
export default {
  props: {
    addUserShow: {
      type: Boolean,
      default: false,
    },
    youtubeChannel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        user: [],
      },
      loading: false,
      userList: [],
      rules: {
        user: {
          required: true,
          message: '请选择用户',
        },
      },
    };
  },
  methods: {
    /**
     * 查询
     * @param {*} query
     */
    remoteMethod(query) {
      if (!query) {
        this.userList = [];
      } else {
        this.getUserList(query);
      }
    },
    /**
     * 获取频道列表
     */
    getUserList(query) {
      this.loading = true;
      queryGkUser({ keyword: query })
        .then((res) => {
          if (res.code == 0) {
            this.userList = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 打开弹窗初始化
     */
    openInit() {},
    /**
     * 关闭弹窗
     */
    close() {
      this.formData.user = [];
      this.$emit('update:addUserShow', false);
    },
    /**
     * 点击确定
     */
    sure() {
      this.$refs.form.validate((validator) => {
        if (validator) {
          this.$showLoading = true;
          batchAddChannelUser({ youtubeChannelId: this.youtubeChannel.id, gkUsers: this.formData.user }).then((res) => {
            if (res.code == 0) {
              this.close();
              this.$emit('getList');
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
