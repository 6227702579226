<template>
  <div>
    <el-input
      class="inline"
      ref="input"
      size="mini"
      v-if="editShow"
      v-model="newValue"
      placeholder="请填写备注"
      @blur="saveMark"
    ></el-input>
    <span v-else>{{ row.displayMark }}</span>
    <el-button
      type="text"
      icon="el-icon-edit"
      @click="editMark(row.displayMark)"
    ></el-button>
  </div>
</template>
<script>
import { editChannelMark } from '@/api/google/youtube.js';
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editShow: false,
      newValue: '',
    };
  },
  methods: {
    editMark() {
      this.newValue = this.row.displayMark;
      this.editShow = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    saveMark() {
      if (this.newValue === this.row.displayMark) {
        this.editShow = false;
        return;
      }
      editChannelMark({ youtubeChannelId: this.row.id, displayMark: this.newValue }).then((res) => {
        if (res.code == 0) {
          this.editShow = false;
          this.$emit('setChannelMark', this.newValue);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
